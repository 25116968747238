import { render } from "./DashboardRiskCard.vue?vue&type=template&id=d7f7df10"
import script from "./DashboardRiskCard.vue?vue&type=script&lang=js"
export * from "./DashboardRiskCard.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "d7f7df10"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d7f7df10', script)) {
    api.reload('d7f7df10', script)
  }
  
  module.hot.accept("./DashboardRiskCard.vue?vue&type=template&id=d7f7df10", () => {
    api.rerender('d7f7df10', render)
  })

}

script.__file = "src/components/dashboard/DashboardRiskCard.vue"

export default script